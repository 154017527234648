
                                @import '@/assets/sass/vuetify/variables.scss';
                                @import '@/assets/sass/colors.scss';
                            

.a-textarea::v-deep {
    textarea {
        max-height: 35vh !important;
        overflow: auto !important;
    }
}
