
                                @import '@/assets/sass/vuetify/variables.scss';
                                @import '@/assets/sass/colors.scss';
                            

.header::v-deep {
    height: 64px !important;
    box-shadow: 0px 2px 4px 0px #0000000d !important;

    .fill {
        height: 100%;
    }

    .inner-container {
        display: flex;
        align-items: center;
    }

    .v-toolbar__content {
        align-items: start;
        padding: 0 !important;

        .header-container {
            padding: 0 !important;

            .header-wrapper {
                margin: 0;
                flex-wrap: nowrap;

                .logo-container {
                    width: 180px;
                }

                .navbar-container {
                    padding-top: 0 !important;
                    padding-bottom: 0 !important;

                    .navbar-wrapper {
                        padding: 0;

                        .navbar-item {
                            border-bottom: 4px solid transparent;
                            transition: 0.1s ease-in;
                            color: black;

                            &:hover {
                                border-bottom: 4px solid $primary-color !important;
                            }

                            .v-list-item__content {
                                margin-top: -4px;
                            }

                            &.v-list-item--active {
                                border-bottom: 4px solid $primary-color;

                                .v-list-item__content {
                                    color: $primary-color !important;
                                }
                            }
                        }
                    }
                }

                .v-btn__content {
                    text-transform: none;
                }
            }
        }
    }
}
