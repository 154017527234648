.clientcabin {
    color: #282b30;

    .headline {
        font-size: 48px !important;
        line-height: 1.1 !important;
        letter-spacing: 0.02em;
        font-weight: 700;
    }

    .subheadline {
        font-size: 40px !important;
        line-height: 1.1 !important;
        letter-spacing: 0.035em;
        font-weight: 700;
    }

    .subtitle {
        font-size: 20px;
        line-height: 36px;
    }

    &.clientcabin-blog {
        background-color: $white;
        color: $black;

        a {
            display: inline-block;
        }
    }
}
