
                                @import '@/assets/sass/vuetify/variables.scss';
                                @import '@/assets/sass/colors.scss';
                            
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap');

// define custom colors based on Vuetify approach, but with higher specificity
@each $color, $value in $ampcolors {
    .v-application.v-application .#{$color} {
        background-color: $value !important;

        &--text {
            color: $value !important;
            caret-color: $value !important;
        }
    }
}

input {
    &:autofill,
    &:autofill:hover,
    &:autofill:focus,
    &:autofill:active {
        box-shadow: 0 0 0 30px $main-background inset !important;
        -webkit-text-fill-color: $secondary-color;
    }
}

.v-application {
    textarea {
        resize: none;
    }

    a {
        word-wrap: break-word;

        &.visitable:visited {
            color: purple;
        }
    }
}

.company-field-static-width {
    width: 344px;
}

.reseller-field-static-width {
    width: 324px;
}

@media (max-width: 960px) {
    .reseller-field-static-width {
        width: 300px;
    }

    .explore-progress {
        margin-bottom: 4px;
    }
}

.v-autocomplete__content {
    width: 300px;
}

// additional custom classes
.position--absolute {
    position: absolute !important;
}

.position--relative {
    position: relative;
}

// Cookie Consent theme
#cc--main {
    --cc-text: #{$secondary-color};
    --cc-btn-primary-bg: #{$primary-color};
    --cc-btn-primary-text: #fff;
    --cc-btn-primary-hover-bg: #{$primary-color};
    --cc-btn-secondary-bg: #{$secondary-color};
    --cc-btn-secondary-text: #fff;
    --cc-btn-secondary-hover-bg: #{$secondary-color};
}

// Some of FontAwesome icons need precise positioning
svg[data-icon='play'] {
    padding-left: 2px;
}

// Custom 10-columns layout, since Vuetify has 12-column layout
// To use, add classes to v-col elements
.col {
    &.col-10-1 {
        flex: 0 0 10%;
        max-width: 10%;
    }

    &.col-10-2 {
        flex: 0 0 20%;
        max-width: 20%;
    }

    &.col-10-3 {
        flex: 0 0 30%;
        max-width: 30%;
    }

    &.col-10-4 {
        flex: 0 0 40%;
        max-width: 40%;
    }

    &.col-10-5 {
        flex: 0 0 50%;
        max-width: 50%;
    }

    &.col-10-6 {
        flex: 0 0 60%;
        max-width: 60%;
    }

    &.col-10-7 {
        flex: 0 0 70%;
        max-width: 70%;
    }

    &.col-10-8 {
        flex: 0 0 80%;
        max-width: 80%;
    }

    &.col-10-9 {
        flex: 0 0 90%;
        max-width: 90%;
    }
    // for 100% just use cols=12
}

.dense-fields {
    .theme--light.v-label {
        font-size: 75%;

        &:not(.v-label--is-disabled) {
            color: #{$secondary-color};
        }
    }
}

.v-application--is-ltr .dense-fields .v-input--selection-controls__input {
    margin-right: 4px;
}
.v-application--is-rtl .dense-fields .v-input--selection-controls__input {
    margin-left: 4px;
}

.scroll-target {
    animation: highlight 1s;
}

@keyframes highlight {
    from {
        background-color: $primary-color;
    }
}
