
                                @import '@/assets/sass/vuetify/variables.scss';
                                @import '@/assets/sass/colors.scss';
                            

.clientcabin-setup::v-deep {
    .v-navigation-drawer__prepend {
        overflow: visible;
    }

    .v-tabs {
        width: calc(100% + 50px);

        .v-tab {
            border-bottom: 2px solid transparent;
            padding-left: 12px;
            padding-right: 12px;
            min-width: 50px;

            &[aria-selected='false'] {
                border-bottom: 2px solid #ccc;
            }
        }

        .v-slide-group__prev,
        .v-slide-group__next {
            min-width: 30px;
            flex-basis: 30px;

            .v-icon__component {
                font-size: 16px;
                width: 16px;
                height: 16px;
            }
        }

        .v-slide-group--has-affixes {
            margin-left: -30px;
        }
    }

    .v-expansion-panel[aria-expanded='false'] {
        border-top: 1px solid $mercury-solid;
    }

    .v-expansion-panel-header__icon > .v-icon {
        font-size: 16px;
        width: 16px;
        height: 16px;

        .v-icon__component {
            width: 16px;
            height: 16px;
        }
    }

    .a-textarea.code textarea {
        font-family: monospace;
        font-size: 85%;
        line-height: 1.3em;
    }

    .v-btn--active.semi-active {
        color: grey;
    }
}
