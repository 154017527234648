
                                @import '@/assets/sass/vuetify/variables.scss';
                                @import '@/assets/sass/colors.scss';
                            

.header::v-deep {
    height: 64px !important;

    .v-toolbar__content {
        align-items: start;
        padding: 0 !important;

        .header-container {
            height: 100%;
            padding: 0 !important;

            .header-wrapper {
                height: 100%;
                margin: 0;

                .logo-container {
                    width: 180px;
                }

                .navbar-container {
                    padding-top: 0 !important;
                    padding-bottom: 0 !important;
                    height: 100%;

                    .navbar-wrapper {
                        height: 100%;
                        padding: 0;

                        .navbar-item {
                            border-top: 4px solid transparent;

                            transition: 0.1s ease-in;

                            &:hover {
                                border-top: 4px solid $primary-color !important;
                            }

                            .v-list-item__content {
                                margin-top: -4px;
                            }

                            &.v-list-item--active {
                                background-color: $secondary-color;
                                border-top: 4px solid $primary-color;
                                border-radius: 0 0 4px 4px;

                                .v-list-item__content {
                                    color: $white !important;
                                }
                            }
                        }
                    }
                }

                .v-btn__content {
                    text-transform: none;
                }
            }
        }
    }
}

.navbar-menu-container {
    .navbar-item {
        border-left: 4px solid transparent;

        transition: 0.1s ease-in;

        &:hover {
            border-left: 4px solid $primary-color !important;
        }

        &.v-list-item--active {
            background-color: $secondary-color;
            border-left: 4px solid $primary-color;

            .v-list-item__content {
                color: $white !important;
            }
        }
    }
}
