
                                @import '@/assets/sass/vuetify/variables.scss';
                                @import '@/assets/sass/colors.scss';
                            

.setup-button::v-deep {
    position: fixed;
    z-index: 5;
    top: 80px;
    right: 15px;

    &.ready > button {
        &:after {
            content: '';
            position: absolute;
            pointer-events: none;
            opacity: 0;
            width: 58px;
            height: 58px;
            background: rgba(254, 13, 102, 0.5);
            border-radius: 100%;
            transform: scale(1, 1) translate(-50%);
            transform-origin: 50% 50%;
            animation: ripple 5s ease-out infinite running;
        }
    }
}

@keyframes ripple {
    0% {
        transform: scale(0, 0);
        opacity: 1;
    }

    4% {
        transform: scale(1.2, 1.2);
        opacity: 1;
    }

    20%,
    100% {
        // adds 5 sec delay
        opacity: 0;
        transform: scale(2, 2);
    }
}
