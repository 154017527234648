
                                @import '@/assets/sass/vuetify/variables.scss';
                                @import '@/assets/sass/colors.scss';
                            

.modal-wrapper {
    &.v-card > .v-card__text {
        color: $secondary-color;
    }

    .modal-title {
        border-top: $primary-color 6px solid;

        .modal-title-column {
            word-break: break-word;
        }
    }
}
