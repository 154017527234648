
                                @import '@/assets/sass/vuetify/variables.scss';
                                @import '@/assets/sass/colors.scss';
                            

.amp-alert::v-deep {
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.102) !important;

    * {
        word-break: break-word;
    }

    a {
        color: $secondary-color;

        &:hover {
            color: $primary-color;
        }
    }

    &.text-caption {
        .v-alert__dismissible {
            .v-icon,
            .v-icon__component {
                width: 16px;
                height: 16px;
                font-size: 16px;
            }
        }
    }
}
